import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";

const PageLayout = ({ pageTitle, children }) => {
  return (
    <main className="page-layout">
      <section className="breadcrumb-area bg-light py-3">
        <div className="container">
          <Breadcrumb className="mb-0">
            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
            <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </section>

      <section className="content-area ptb-100">
        <div className="container">
          <header className="page-header mb-4">
            <h1 className="page-title">{pageTitle}</h1>
          </header>

          <div className="row">
            <div className="col-12">{children}</div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default PageLayout;
