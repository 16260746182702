import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => (
  <div
    className="container d-flex flex-column align-items-center justify-content-center text-center"
    style={{ height: "100vh" }}
  >
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <Link to="/" className="btn btn-primary mt-3">
      Go Back Home
    </Link>
  </div>
);

export default NotFound;
