import React, { useEffect } from "react";
import ResultCard from "./ResultCard";

const SearchResults = ({ results, options }) => {
  useEffect(() => {
    console.log("results", results, "options", options);
  }, [results, options]); // Include options as a dependency here

  const filteredResults = results
    .filter((result) => result.rooms.length > 0)
    .sort((a, b) => {
      // Sorting in descending order based on starRating
      const starRatingA = a.propertyInfo.starRating || 0;
      const starRatingB = b.propertyInfo.starRating || 0;
      return starRatingB - starRatingA;
    });

  return (
    <div className="search-results">
      <h2>Search Results</h2>
      {filteredResults.length > 0 ? (
        <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
          {filteredResults.map((result, index) => (
            <ResultCard key={index} result={result} options={options} />
          ))}
        </div>
      ) : (
        <div className="alert alert-warning">No accommodations available</div>
      )}
    </div>
  );
};

export default SearchResults;
