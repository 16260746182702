import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import { countriesData } from "../Controllers/CountriesData";

const GuestInput = ({
  adults: initialAdults,
  children: initialChildren,
  nationality,
  rooms: initialRooms,
  onAdultsChange,
  onChildrenChange,
  onNationalityChange,
  onRoomsChange,
  roomData,
  setRoomData,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedNationality, setSelectedNationality] = useState(null);
  const [adults, setAdults] = useState(initialAdults);
  const [children, setChildren] = useState(initialChildren);
  const [rooms, setRooms] = useState(initialRooms);

  useEffect(() => {
    setSelectedNationality(nationality);
  }, [nationality]);

  useEffect(() => {
    setAdults(initialAdults);
  }, [initialAdults]);

  useEffect(() => {
    setChildren(initialChildren);
  }, [initialChildren]);

  useEffect(() => {
    setRooms(initialRooms);
  }, [initialRooms]);

  const handleSave = () => {
    setShowModal(false);
    onRoomsChange(roomData);
    onNationalityChange(selectedNationality);
    onAdultsChange(adults);
    onChildrenChange(children);
  };

  const addRoom = () => {
    const newRoom = { adults: 1, children: 0, childrenAges: [] };
    setRoomData([...roomData, newRoom]);
    setAdults(adults + 1);
    calculateTotals([...roomData, newRoom]);
  };

  const removeRoom = (index) => {
    if (index !== 0) {
      const updatedRooms = [...roomData];
      updatedRooms.splice(index, 1);
      setRoomData(
        updatedRooms.map((room, i) => ({ ...room, title: `Room ${i + 1}` }))
      );
    }
  };

  const handleAdultsChange = (value, index) => {
    const updatedRooms = [...roomData];
    updatedRooms[index].adults = value;
    setRoomData(updatedRooms);
    calculateTotals(updatedRooms);
  };

  const handleChildrenChange = (value, index) => {
    const updatedRooms = [...roomData];
    updatedRooms[index].children = value;
    setRoomData(updatedRooms);
    calculateTotals(updatedRooms);
  };

  const handleChildAgeChange = (age, childIndex, roomIndex) => {
    const updatedRooms = [...roomData];
    updatedRooms[roomIndex].childrenAges[childIndex] = age;
    setRoomData(updatedRooms);
  };

  const calculateTotals = (updatedRooms) => {
    let totalAdults = 0;
    let totalChildren = 0;
    let totalRooms = 0;
    updatedRooms.forEach((room) => {
      totalAdults += parseInt(room.adults);
      totalChildren += parseInt(room.children);
    });
    totalRooms = updatedRooms.length;
    setAdults(totalAdults);
    setChildren(totalChildren);
    setRooms(totalRooms);
  };

  const getGuestsDescription = () => {
    const adultsText = adults === 1 ? "1 adult" : `${adults} adults`;
    const childrenText =
      children === 1
        ? " & one child"
        : children === 0
        ? ""
        : ` & ${children} children`;
    const nationalityText = nationality ? `from ${nationality.label}` : "";
    const roomsText =
      roomData.length === 1
        ? "in one room"
        : roomData.length > 1
        ? `in ${roomData.length} rooms`
        : "";

    return `${adultsText} ${childrenText}, ${roomsText} ${nationalityText}`;
  };

  return (
    <div>
      <input
        type="text"
        id="guests"
        name="guests"
        className="form-control"
        value={getGuestsDescription()}
        onClick={() => setShowModal(true)}
        readOnly
      />

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Guest Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="nationality">
            <Form.Label>Nationality</Form.Label>
            <Select
              options={countriesData}
              defaultValue={selectedNationality}
              onChange={(selectedOption) =>
                setSelectedNationality(selectedOption)
              }
            />
          </Form.Group>
          <hr></hr>
          {roomData.map((room, roomIndex) => (
            <div key={roomIndex} className="mb-3">
              <div className="d-flex justify-content-between align-items-center mt-1">
                <h5 className="fw-bold">
                  {room.title || `Room ${roomIndex + 1}`}
                </h5>
                {roomData.length > 1 && (
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => removeRoom(roomIndex)}
                  >
                    Remove {room.title || `Room ${roomIndex + 1}`}
                  </Button>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId={`adults-${roomIndex}`}>
                    <Form.Label>Adults</Form.Label>
                    <Form.Control
                      type="number"
                      value={room.adults}
                      min={1}
                      max={10}
                      onChange={(e) =>
                        handleAdultsChange(e.target.value, roomIndex)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group controlId={`children-${roomIndex}`}>
                    <Form.Label>Children</Form.Label>
                    <Form.Control
                      type="number"
                      value={room.children}
                      min={0}
                      max={10}
                      onChange={(e) =>
                        handleChildrenChange(e.target.value, roomIndex)
                      }
                    />
                  </Form.Group>
                </div>
              </div>
              {room.children > 0 && (
                <div className="row">
                  {[...Array(parseInt(room.children))].map((_, childIndex) => (
                    <div key={childIndex} className="col-md-4">
                      <Form.Group
                        controlId={`child-age-${roomIndex}-${childIndex}`}
                      >
                        <Form.Label>Child {childIndex + 1} Age</Form.Label>
                        <Form.Control
                          as="select"
                          value={room.childrenAges[childIndex]}
                          onChange={(e) =>
                            handleChildAgeChange(
                              e.target.value,
                              childIndex,
                              roomIndex
                            )
                          }
                        >
                          {[...Array(18)].map((_, index) => (
                            <option key={index} value={index}>
                              {index === 0
                                ? "0 year old"
                                : `${index} year${index > 1 ? "s" : ""} old`}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleSave}>
            Save
          </Button> */}
          <Button variant="success" className="mt-1" onClick={addRoom}>
            Add Room
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GuestInput;
