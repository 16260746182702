import React from "react";
import PageLayout from "../components/layout/pageLayout";
import ContactForm from "../components/forms/ContactForm";

const ContactUs = ({ siteData }) => {
  // Extract and parse the s_info field as JSON
  const s_info = siteData?.sitedata?.[0]?.s_info
    ? JSON.parse(siteData.sitedata[0].s_info)
    : {};
  const contactInfo = s_info?.Contact || {};

  return (
    <PageLayout pageTitle="Contact Us">
      <main>
        <section className="contact-info my-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>Contact Information</h2>
                <p>If you have any questions, feel free to reach out to us:</p>
                <ul className="list-unstyled">
                  <li>
                    <strong>Address:</strong> {contactInfo.Address}
                  </li>
                  <li>
                    <strong>Phone:</strong> {contactInfo.Phone}
                  </li>
                  <li>
                    <strong>Email:</strong> {contactInfo.Email}
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h2>Send Us a Message</h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </section>
      </main>
    </PageLayout>
  );
};

export default ContactUs;
